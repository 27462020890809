"use client";

import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import AnimatedButton from "@/app/components/ui/AnimatedButton";

const focusClasses =
  "focus:outline-none focus-visible:ring-4 focus-visible:ring-blue focus-visible:ring-opacity-100 focus-visible:ring-offset-2 focus-visible:ring-offset-white hover:ring-4 hover:ring-blue hover:ring-opacity-100 hover:ring-offset-2 hover:ring-offset-white transition-shadow duration-300 rounded";

const NotFoundPage = () => {
  const headline = "404 - Page Not Found";
  const description = "Oops! The page you're looking for doesn't exist.";
  const buttonText = "Go to Home";
  const imageAlt = "404 Not Found Illustration";

  return (
    <>
      <section
        id="not-found"
        className="relative w-full min-h-screen flex flex-col justify-center items-center bg-light text-blue"
      >
        <motion.div
          className="w-full max-w-7xl mx-auto px-4 custom-container sm:px-6 lg:px-8 z-10 flex flex-col items-center text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Image
            src="/images/not-found.svg"
            alt={imageAlt}
            width={300}
            height={300}
            priority
            quality={100}
            className="mb-8"
          />
          <h1
            className={`text-4xl xs:text-5xl md:text-6xl font-bold leading-tight ${focusClasses}`}
            tabIndex={0}
          >
            {headline}
          </h1>
          <p
            className={`mt-4 text-md xs:text-lg md:text-xl mb-8 leading-relaxed ${focusClasses}`}
            tabIndex={0}
          >
            {description}
          </p>
          <AnimatedButton
            showArrow
            variant="primary"
            aria-label={buttonText}
            className={`w-full sm:w-auto ${focusClasses}`}
            onClick={() => (window.location.href = "/")}
          >
            {buttonText}
          </AnimatedButton>
        </motion.div>
      </section>
    </>
  );
};

export default NotFoundPage;
